import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class SensitiveWordsService {
  @Inject(HttpService) private http!: HttpService;

  //获取敏感词列表
  public getSensitiveWordsList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/sensitiveWord/getSensitiveWordList', param);
  }
  //插入敏感词
  public insertWords(param: Dict<any>): Promise<any> {
    return this.http.post('/api/sensitiveWord/insertSensitiveWord',param);
  }

  //修改敏感词
  public modifySensitiveWordsList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/sensitiveWord/updateSensitiveWord', param);
  }

  //删除敏感词
  public deleteSensitiveWord(param: Dict<any>): Promise<any> {
    return this.http.post('/api/sensitiveWord/delSensitiveWord',param);
  }
}
