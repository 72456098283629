






































































































































































import { Component, Vue } from 'vue-property-decorator';
import { CacheService, Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { SensitiveWordsService } from '@/services/sensitivewords-service';
import { isArray, some } from 'lodash';
import { PermissionService } from '@/services/common/permission.service';
import { UserService } from '@/services/user-service';

@Component
export default class Sensitivewords extends Vue {
  public loading: boolean = false;
  public inputText: string = '';
  public inputSearch: string = '';
  // private changedValue: any = '';
  private isScope: any = null;
  private form: any = {};
  private tableData: Dict<any> = [];
  private dialogFormVisible: boolean = false;
  private userInfo: any = {};
  //超管
  private hashandlePermission: boolean = false;
  // private addSensitiveWord:boolean = false;
  private sensitiveType: string = '';
  private pagination: any = {
    pageSize: 10,
    total: 0,
    pageNum: 1
  };

  @Inject(SensitiveWordsService)
  private sensitiveWordsService!: SensitiveWordsService;
  @Inject(PermissionService) private permissionService!: PermissionService;
  @Inject(CacheService) private cacheSvc!: CacheService;
  @Inject(UserService) private userService!: UserService;

  public async mounted(): Promise<void> {
    this.getList();
  }

  // 是否有权限
  public created(): void {
    this.checkSuperAdmin();
    this.selfname();
  }

  //超管判断
  public async checkSuperAdmin() {
    let result = await this.userService.checkUserAdminStatus();
    console.log('result', result);
    this.hashandlePermission = result.isSuperAdmin == 1;
    // console.log('hashandlePermission',hashandlePermission);
  }

  //判断是否是本人(本人才执行编辑删除操作，否则按钮禁用)
  private selfname() {
    const user = this.cacheSvc.get('user');
    const { fullName, id } = user;
    return fullName + `(${id})`;
  }

  public async getList(val?: any, type?: string) {
    let form: any = { pageNum: 1, pageSize: 10 };
    if (type === 'pagination') {
      const { pageSize, pageNum } = val;
      form.pageNum = pageNum;
      form.pageSize = pageSize;
      form = Object.assign({}, this.form, form);
    } else if (type === 'search') {
      form = Object.assign(form, val);
      form.pageSize = 10;
      form.pageNum = 1;
    }
    this.form = form;
    try {
      this.loading = true;
      const param = {
        pageNum: this.form.pageNum,
        pageSize: this.form.pageSize,
        total: this.form.total,
        word: this.inputSearch == null ? '' : this.inputSearch
      };
      const res = await this.sensitiveWordsService.getSensitiveWordsList(param);
      if (!res) {
        this.$notify.error({
          title: '错误',
          message: '获取列表失败，请您重试。'
        });
        return;
      }
      const { records, current, total, size } = res;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.pagination.pageNum = current;
      if (records && isArray(records)) {
        this.tableData = records;
      }
    } finally {
      this.loading = false;
    }
  }
  public async addSensitiveword(): Promise<void> {
    const isExit = this.tableData.some((item: any) => {
      return item.word === this.inputText;
    });
    //新增
    if (this.isScope === null) {
      // this.addSensitiveWord = true
      //存在敏感词
      if (isExit) {
        this.$message({
          message: '敏感词已存在',
          type: 'info'
        });
        //不存在敏感词
      } else {
        if (this.inputText !== '') {
          try {
            this.loading = true;
            const param = {
              word: this.inputText
            };
            const res = await this.sensitiveWordsService.insertWords(param);
            console.log('xinzennde', res);
            this.getList();
            this.dialogFormVisible = false;
            this.inputText = '';
          } catch (e) {
            this.loading = false;
          }
        } else {
          this.$message({
            message: '敏感词不能为空',
            type: 'info'
          });
        }
      }
    } else {
      if (isExit) {
        this.$message({
          message: '敏感词已存在',
          type: 'info'
        });
        //不存在敏感词
      } else {
        const { row, $index } = this.isScope;
        this.tableData[$index].word = this.inputText;
        if (this.inputText !== '') {
          try {
            this.loading = true;
            console.log('this.isScope', this.isScope);
            const param1 = {
              id: row.id,
              word: this.inputText,
              commit: 1,
              create_user: ''
            };
            const res =
              await this.sensitiveWordsService.modifySensitiveWordsList(param1);
          } catch (e) {
            this.loading = false;
          } finally {
            this.cancelSensitiveSearch();
            this.getList();
          }
        } else {
          this.$message({
            message: '敏感词不能为空',
            type: 'info'
          });
        }
      }
    }
  }

  public async handleConfiguration(index: any, row: any): Promise<void> {
    try {
      await this.$confirm('是否已经在企业微信中完成了敏感词设置?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      });
      const param = {
        id: row.id,
        pass: 1
      };
      const res = await this.sensitiveWordsService.modifySensitiveWordsList(
        param
      );
      this.getList();
    } catch (e) {}
  }
  public async removeSensitiveword(index: any, row: any): Promise<void> {
    try {
      await this.$confirm('是否确认删除当前敏感词?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      });
      this.tableData.splice(index, 1);
      const res = await this.sensitiveWordsService.deleteSensitiveWord({
        id: row.id
      });
    } catch (e) {}
  }

  public cancelSensitiveSearch(): void {
    this.dialogFormVisible = false;
    this.inputText = '';
    this.isScope = null;
    this.getList()
  }

  public async editorSensitiveword(scope?: any): Promise<void> {
    //编辑
    if (scope) {
      this.sensitiveType = '编辑敏感词';
      this.inputText = scope.row.word;
      this.isScope = scope;
    } else {
      this.sensitiveType = '增加敏感词';
      this.isScope = null;
    }
    this.dialogFormVisible = true;
  }
}
